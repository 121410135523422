<template>
    <div class="left_product_item">
        <div class="image">
            <Link :href="`/products/${product.category_slug}/${product.slug}`">
                <img ref="lazyImage" class="lazy_image_opacity" :src="load_image('loader.gif')" :data-src="load_image(product.product_image?.url)" />
            </Link>
        </div>
        <div class="info">
            <Link :href="`/products/${product.category_slug}/${product.slug}`">
            <h4 class="product_title">
                {{ product.title }}
            </h4>
            <div v-if="product.product_manufaturer">
                {{ product.product_manufaturer.title }}
            </div>
            <div class="price" v-if="product.current_discount_price">
                <div class="new">
                    {{ number_format(product.current_discount_price) }} ৳
                </div>
                <div class="old">
                    {{ number_format(product.current_price) }} ৳
                </div>
            </div>
            <div class="price" v-else>
                <div class="new">
                    {{ number_format(product.current_price) }} ৳
                </div>
            </div>
            </Link>
            <div>
                <button v-if="product.type !== 'fashion'" class="cart_btn btn" @click.prevent="add_to_cart(product)">
                    <i class="fa fa-shopping-cart"></i>
                    Add To Cart <span v-if="is_in_cart(product).status">( {{ is_in_cart(product).qty }} )</span>
                </button>

                <Link v-if="product.type == 'fashion'" :href="`/product-details/${product.slug}`"
                    class="cart_btn btn d-inline-block">
                    Buy Now
                </Link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { cart_store } from "../Store/cart_store";
export default {
    props: ["product"],
    mounted: function(){
        this.lazyLoadImages();
    },
    methods: {
        ...mapActions(cart_store, [
            "add_to_cart",
            "is_in_cart",
        ]),
        lazyLoadImages: function() {
            const imageElements = this.$refs.lazyImage;

            const options = {
                root: null, // viewport
                rootMargin: "0px",
                threshold: 0.1, // Trigger when 10% of the image is visible
            };

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const imgElement = entry.target;
                        const dataSrc = imgElement.getAttribute("data-src");

                        if (dataSrc) {
                            imgElement.src = dataSrc; // Load the image
                            observer.unobserve(imgElement); // Stop observing once loaded
                            imgElement.classList.remove('lazy_image_opacity');
                        }
                    }
                });
            }, options);

            observer.observe(imageElements);
        },
    },
    computed: {
        ...mapState(cart_store, [
            "carts"
        ]),
    }
}
</script>
